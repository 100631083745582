import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { LoadingIndicator } from '@packages/ui';
import { initTracker, useAnalytics } from '@packages/analytics';

import { AppRouter } from './routes';

import useScript from 'shared/hooks/useScript';
import ErrorPage from 'shared/components/ErrorPage';
import { useRootStore } from 'shared/stores';
import Logger from 'shared/models/Logger';
import { IS_PROD_ENV } from 'shared/config/Config';
import User from 'shared/models/User';

const App = observer(() => {
  const {
    login,
    isLoading,
    isReady,
    loadingMessage,
    width,
    setWidth,
    error,
  } = useRootStore();

  const { setGclid } = useAnalytics();

  useEffect(() => {
    // Handle login when the App is opened
    login();
  }, [login]);

  // inject statuspage JS (if prod)
  useScript('https://q4f4gt6nwhh0.statuspage.io/embed/script.js');

  // Fires up the initialization of the Analytics events
  initTracker(IS_PROD_ENV, Logger);

  /**
   * Update state width if width differs so much that the style should be changed
   * to mobile/desktop version. This is to prevent continuous re-rendering when
   * resizing the page.
   */
  const updateWidth = () => {
    if (!document.body) return;

    if (
      (width > 1000 && document.body.clientWidth <= 1000) ||
      (width <= 1000 && document.body.clientWidth > 1000)
    ) {
      setWidth(document.body.clientWidth);
    }
  };

  useEffect(() => {
    // Set GCLID on load to use during tracing & sign-up
    const urlParams = new URLSearchParams(window.location.search);
    const gclidFromUrl = urlParams.get('gclid');

    if (gclidFromUrl) {
      User.setGclid(gclidFromUrl);
      setGclid(gclidFromUrl);
    }
  });

  // event listener for page resize so we know when to render mobile / desktop header
  useEffect(() => {
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  });

  if (isLoading || !isReady) {
    return <LoadingIndicator height="100vh" label={loadingMessage} />;
  }

  if (error) {
    return (
      <ErrorPage>
        Error while fetching data, refresh the page to try again.
      </ErrorPage>
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AppRouter />;
});

export default App;
