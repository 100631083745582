import {
  IS_DASHBOARD_PROD_HCAPTCHA,
  IS_DASHBOARD_YELLOW_HCAPTCHA,
} from 'shared/config/Config';

export const PROD_REDIRECT_WHITE_LIST = [
  'docs.hcaptcha.com',
  'dashboard.hcaptcha.com',
];

export const YELLOW_REDIRECT_WHITE_LIST = [
  'yellowdocs.hmt.ai',
  'yellowdash.hmt.ai',
];

export const isRedirectUrlAllowed = (url: string) => {
  if (process.env.NODE_ENV !== 'production') return true;

  try {
    const { hostname, protocol } = new URL(url);

    if (IS_DASHBOARD_PROD_HCAPTCHA) {
      return (
        PROD_REDIRECT_WHITE_LIST.includes(hostname) && protocol === 'https:'
      );
    }

    if (IS_DASHBOARD_YELLOW_HCAPTCHA) {
      return (
        YELLOW_REDIRECT_WHITE_LIST.includes(hostname) && protocol === 'https:'
      );
    }

    return false;
  } catch (e) {
    return false;
  }
};

export const constructSafeUrl = (url: string, hash?: string) => {
  try {
    const { origin, pathname } = new URL(url);

    return `${origin}${pathname}${hash ?? ''}`;
  } catch (e) {
    return '';
  }
};

export const getUrlWithBackurlRedirect = (url: string) => {
  const backurl = new URLSearchParams(window.location.search).get('backurl');

  if (backurl && isRedirectUrlAllowed(backurl)) {
    const urlInstance = new URL(url);
    const urlSearchParams = new URLSearchParams(urlInstance.search);

    urlSearchParams.append('next', constructSafeUrl(backurl));
    urlInstance.search = urlSearchParams.toString();

    return urlInstance.toString();
  }

  return url;
};
