import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Tabs, Tab, Text } from '@packages/ui';

export interface PageTabsV2Props {
  className?: string;
  routes: any;
}

export const PageTabsV2: React.FC<PageTabsV2Props> = ({
  className = '',
  routes,
}) => {
  const { t } = useTranslation('generic');
  const { push: navigate, location } = useHistory();

  const handleTabClick = (path: string = '') => {
    navigate(path);
  };

  const visibleTabs = routes.filter(route => route?.tabs !== false);

  return (
    <Tabs className={className}>
      {visibleTabs.map(({ title = '', path }) => {
        const isActive = Array.isArray(path)
          ? path.find(innerPath => location.pathname.includes(innerPath))
          : location.pathname === path;

        return (
          <Tab
            key={title}
            value={Array.isArray(path) ? path[0] : path}
            isActive={isActive}
            onSelect={() =>
              handleTabClick(Array.isArray(path) ? path[0] : path)
            }
          >
            <Title>{t(title)}</Title>
          </Tab>
        );
      })}
    </Tabs>
  );
};

const Title = styled(Text)`
  text-transform: capitalize;
`;
