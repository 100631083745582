/**
 * A small module to handle storing keys and values to a user's local storage
 * @type {Object}
 */
const Storage = {
  get(key) {
    const storageItem = localStorage.getItem(key);

    if (!storageItem) return null;

    try {
      const data = JSON.parse(storageItem);
      return data;
    } catch (e) {
      return storageItem;
    }
  },

  set<T>(key: string, value: T) {
    let val: T = value;
    if (typeof val === 'object') val = JSON.stringify(value);
    localStorage.setItem(key, val);
  },

  remove(key) {
    localStorage.removeItem(key);
  },
};
export default Storage;
