import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Color } from '@packages/ui';

export interface LoginViewsProps {
  selected: string;
  history: any;
}

const LoginViews: React.FC<LoginViewsProps> = ({
  selected = 'user',
  history,
}) => {
  const { t } = useTranslation('portal');

  const handleKeyPress = (evt, type) => {
    if (evt.key === 'Enter' || evt.key === 'Space') {
      handleSelect(type);
    }
  };

  const handleSelect = type => {
    if (selected !== type) {
      // XXX !!! remove after real state handling
      const route = type === 'user' ? '/login' : '/login/org';
      type === 'user' && (window.organization = undefined);
      history.push(route + window.location.search);
    }
  };

  return (
    <Wrapper data-cy="oauth">
      <Type
        data-cy="sso-option-standard"
        role="button"
        tabIndex={0}
        onClick={() => handleSelect('user')}
        onKeyDown={evt => handleKeyPress(evt, 'user')}
      >
        {t('User')}
        <Line side="left" active={selected === 'user'} />
      </Type>
      <Type
        data-cy="sso-option-organization"
        role="button"
        tabIndex={0}
        onClick={() => handleSelect('org')}
        onKeyDown={evt => handleKeyPress(evt, 'org')}
      >
        {t('Organization')}
        <Line side="right" active={selected === 'org'} />
      </Type>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-flex;
  height: auto;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #424242;
  margin-top: 10px;
`;

const Type = styled.div`
  margin-right: 10px;
  cursor: pointer;
  outline: none !important;

  color: ${Color.grey600};
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${Color.teal200};

  transform: scaleX(${props => (props.active ? 1 : 0)});
  transform-origin: ${props => (props.side === 'left' ? '100%' : '0%')} 100%;
  transition: transform 0.3s cubic-bezier(0.65, 0, 0.35, 1);
`;

export default withRouter(LoginViews);
