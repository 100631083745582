import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Color, Icon, Size, Text, useToast } from '@packages/ui';

import { AllowedAccount } from '../../data/types/AllowedAccount';
import AccountSelect from '../../components/auth/AccountSelect';
import { Api } from '../../data/api';

import { useRootStore } from 'shared/stores';
import Logo from 'shared/components/logo/Logo';
import PageLoader from 'shared/components/PageLoader';
import Account from 'shared/models/Account';
import Logger from 'shared/models/Logger';

const SelectAccount: React.FC = observer(() => {
  const { t } = useTranslation(['generic', 'portal']);
  const routerHistory = useHistory();
  const { userStore } = useRootStore();
  const toast = useToast();

  const [isPageLoading, setIsPageLoading] = useState(false);

  const availableAccounts: AllowedAccount[] = userStore.getAvailableAccounts();

  useEffect(() => {
    const fetchAuthorizedAccounts = async () => {
      try {
        const { data } = await Api.fetchAvailableAccounts();
        userStore.setAvailableAccounts(data.available_accounts);
      } catch (err) {
        Logger.error(
          `couldn't fetch Authorized Accounts in SelectAccount: ${err}`,
        );
        toast.error(
          t('Could not load accounts; please reload the page', {
            ns: 'portal',
          }),
        );
      }
    };
    fetchAuthorizedAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = async () => {
    setIsPageLoading(true);

    try {
      await Account.logout();
      routerHistory.replace({ pathname: '/login' });
    } catch (err) {
      Logger.error(`couldn't log out: ${err}`);
      toast.error(t('Could not log out, please try again'));

      setIsPageLoading(false);
    }
  };

  const handlePageLoad = () => {
    setIsPageLoading(!isPageLoading);
  };

  if (isPageLoading) {
    return <PageLoader />;
  }

  return (
    <Page>
      <ContentContainer>
        <Logo width={120} height={32} />
        <TopMenu>
          <TopMenuTitle variant="small">
            {t('Select an Account', { ns: 'portal' })}
          </TopMenuTitle>
          <LogoutButton onClick={handleLogout}>
            <Icon icon="logout" iconSize="16" fill={Color.red100} />
            <LogoutButtonText>{t('Log Out')}</LogoutButtonText>
          </LogoutButton>
        </TopMenu>
        <AccountSelect
          availableAccounts={availableAccounts}
          onAccountSelect={handlePageLoad}
          isPageView
        />
      </ContentContainer>
    </Page>
  );
});

export default SelectAccount;

const Page = styled.div`
  box-sizing: border-box;
  padding: 48px;
  background-color: ${Color.white};

  width: 100vw;
  min-height: calc(100vh - 20px);

  display: flex;
  justify-content: center;

  @media (max-width: ${Size.mobile}) {
    padding: 16px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 760px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopMenu = styled.div`
  width: 100%;
  margin-top: 32px;

  display: flex;
  justify-content: space-between;
`;

const TopMenuTitle = styled(Text)`
  color: ${Color.grey800};

  font-weight: 600;
  font-size: 18px;
`;

const LogoutButton = styled.div`
  display: flex;
  cursor: pointer;
`;

const LogoutButtonText = styled(Text)`
  color: ${Color.grey500};
  margin-left: 8px;
`;
