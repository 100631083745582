import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text, Icon } from '@packages/ui';

import { SupportedBrowsers } from 'shared/components/SupportedBrowsers';

interface UnavailablePasskeysOptionProps {
  className?: string;
}

const UnavailablePasskeysOption: React.FC<UnavailablePasskeysOptionProps> = ({
  className,
}) => {
  const { t } = useTranslation('generic');

  return (
    <Container className={className}>
      <SupportInfoContainer>
        <WarningIconContainer>
          <Icon icon="warningCircle" iconSize="16" />
        </WarningIconContainer>
        <Text>{t('This browser does not support passkeys.')}</Text>
      </SupportInfoContainer>
      <SupportedBrowsers iconSize="24" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const SupportInfoContainer = styled(Container)`
  flex-direction: row;
`;

const WarningIconContainer = styled.div``;

export default UnavailablePasskeysOption;
