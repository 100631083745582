import * as Sentry from '@sentry/browser';

import { HTTP } from './enums';
import { mapSentryError } from './errorMapping';
import { IFetchOptions, FetchResponse } from './types';
import { formatRequestBody } from './request';
import { getData } from './response';
import { HttpError } from './Error';

/**
 * Main function of the package, that makes an authenticated HTTP request.
 *
 * @template T
 * @param {string} url - The endpoint URL.
 * @param {IFetchOptions} options - The request options.
 * @returns {Promise<FetchResponse<T>>} - The response data or an error.
 */
export async function fetchWithAuth<T>(
  url: string,
  options: IFetchOptions,
): Promise<FetchResponse<T>> {
  const {
    CSRFToken,
    method = HTTP.GET,
    body: data,
    headers = {},
    contentType = 'application/json',
    signal,
  } = options;

  try {
    const body = formatRequestBody(data, contentType);

    // Set Headers
    const authHeaders = new Headers({
      'X-CSRF-Token': CSRFToken,
      'Content-Type': contentType,
      ...headers,
    });

    const options: RequestInit = {
      method,
      headers: authHeaders,
      credentials: 'include',
      signal,
    };

    if (body !== undefined && method !== HTTP.GET) {
      options.body = body;
    }

    const res = await fetch(url, options);

    const isResOk = res?.ok ?? (res.status >= 200 && res.status < 300);

    if (!isResOk) {
      const sentryError = mapSentryError(res.status, data);
      Sentry.addBreadcrumb(sentryError);

      throw new HttpError(sentryError.message, res.status);
    }

    const responseData: T = (await getData(res)) ?? null;
    return [responseData, null];
    // eslint-disable-next-line prettier/prettier
  } catch (error: any) {
    return [null, error];
  }
}
