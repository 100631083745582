import { useEffect } from 'react';

// Config
import { APP } from 'shared/config/Config';
// only used for statuspage, which breaks a11y
const currentLocation = window.location.href;
const isA11y = currentLocation.includes('accessibility');

const useScript = (url: string, attributes: any = {}) => {
  useEffect(() => {
    if (APP.env === 'production' && !isA11y && url) {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      Object.entries(attributes).forEach(([attributeName, attributeValue]) => {
        script.setAttribute(attributeName, attributeValue as string);
      });
      if (document && document.body) document.body.appendChild(script);
      return () => {
        if (document && document.body) document.body.removeChild(script);
      };
    }

    return undefined;
  }, [url]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useScript;
