import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { SubmitButton, Form, FormHeader, TextInput } from 'main/components';
import Account from 'shared/models/Account';
import History from 'shared/models/History';
import Logger from 'shared/models/Logger';
import { TEXT_BRANDING } from 'shared/config/Config';

function PasswordChange() {
  const [feedback, setFeedback] = useState({ text: '', type: '' });
  const [password, setPassword] = useState('');
  const [copy, setCopy] = useState('');

  const { t } = useTranslation('portal');

  const onSubmit = async () => {
    if (!password || !copy) return;

    if (password !== copy) {
      setFeedback({
        text: t('Passwords do not match.'),
        type: 'error',
      });
      return;
    }

    try {
      await Account.changePassword(password, '');
      History.push('/login');
      // refresh to pull in new cookies
      History.go(0);
    } catch (e) {
      Logger.error(`couldn't change password in PasswordChange: ${e}`);

      setFeedback({
        text: e.message || t('An error occurred, please try again'),
        type: 'error',
      });
    }
  };

  const handleFocus = () => {
    setFeedback({ text: '', type: '' });
  };

  const handleBlur = () => {
    if (password === '' || copy === '') return;

    if (password !== copy) {
      setFeedback({
        text: t('Passwords do not match.'),
        type: 'error',
      });
    } else {
      setFeedback({ text: '', type: '' });
    }
  };

  const handleKeyDown = (evt: KeyboardEvent) => {
    const { keyCode } = evt;
    if (keyCode === 13) {
      // If enter press
      onSubmit();
    }
  };

  return (
    <Form
      ariaLabel={t('{{brand}} Password Change', {
        brand: TEXT_BRANDING,
      })}
    >
      <FormHeader withUnderline={false} feedback={feedback}>
        {t('Set Password')}
      </FormHeader>
      <InputWrapper>
        <InputPassword
          placeholder={t('New Password (Use numbers, symbols..)')}
          label="password"
          type="password"
          onKeyDown={handleKeyDown}
          onChange={setPassword}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <TextInput
          placeholder={t('Confirm New Password')}
          label="password-copy"
          type="password"
          onChange={setCopy}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </InputWrapper>

      <ButtonWrapper>
        <ActionButton
          onClick={onSubmit}
          aria-label={t('Submit')}
          aria-relevant="text"
          aria-live="polite"
        >
          {t('Submit')}
        </ActionButton>
      </ButtonWrapper>
    </Form>
  );
}

const InputWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const InputPassword = styled(TextInput)`
  margin-bottom: 15px;
  position: relative;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ActionButton = styled(SubmitButton)`
  font-size: 14px;

  background-color: #0082bf;

  :hover {
    background-color: #0082bf;
  }

  :focus {
    background-color: #0082bf;
  }
`;

export default PasswordChange;
