import { StripeConstructorOptions } from '@stripe/stripe-js';
import { loadStripe as _loadStripe } from '@stripe/stripe-js/pure';
import pRetry, { Options as RetryOptions } from 'p-retry';

import Logger from 'shared/models/Logger';

export const loadStripeWithRetry = (
  options?: StripeConstructorOptions & {
    retryOptions?: RetryOptions;
  },
) => {
  const { retryOptions, ...stripeOpts } = options || {};

  return pRetry(
    () => _loadStripe(process.env.AUTH_TOKEN_STRIPE || '', stripeOpts),
    {
      onFailedAttempt: error => {
        Logger.warn(
          `Attempt to load stripe ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`,
        );
      },
      ...(retryOptions || {}),
    },
  );
};
