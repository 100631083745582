import React from 'react';
import styled from 'styled-components';
import { Color, Text, Icon, IconProp } from '@packages/ui';

interface DocsLinkProps {
  className?: string;
  href: string;
  title?: string;
  isIcon?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  icon?: IconProp;
}

const DocsLink: React.FC<DocsLinkProps> = ({
  className,
  href,
  title,
  children,
  isIcon,
  onClick,
  icon,
}) => {
  const handleClick = () => {
    onClick?.();
  };

  return (
    <Container
      className={className}
      href={href}
      target="_blank"
      rel="noopener"
      onClick={handleClick}
    >
      <LinkText variant="default">{title || children}</LinkText>
      {(isIcon || icon) && (
        <LinkIcon icon={icon ?? 'arrowUpRight'} iconSize="16" />
      )}
    </Container>
  );
};

const Container = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 600;
  color: ${Color.blue400};
  text-decoration: underline;

  &:hover,
  &:active {
    color: ${Color.blue500};
  }
`;

const LinkText = styled(Text)`
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

const LinkIcon = styled(Icon)`
  margin-left: 4px;
`;

export default DocsLink;
