import React from 'react';
import styled from 'styled-components';
import { Color, Icon } from '@packages/ui';
import { Trans, useTranslation } from 'react-i18next';

import Logo from 'shared/components/logo/Logo';
import { IS_FOUNDATION } from 'shared/config/Config';

export const AccountDeletionConfirmation = () => {
  const { t } = useTranslation('generic');

  const width = IS_FOUNDATION ? 190 : 125;
  const height = IS_FOUNDATION ? 190 : 32;

  return (
    <PageWrapper>
      <LogoWrapper>
        <Logo width={width} height={height} />
      </LogoWrapper>
      <Content>
        <IconWrapper>
          <Icon icon="userX" iconSize="l" />
        </IconWrapper>
        <InfoContainer>
          <Title>{t('Account Deletion Confirmation')}</Title>
          <Message>
            <Trans
              t={t}
              i18nKey="In 24h your account will be deleted. <br /> If you need assistance or have any questions, please <br /> <4>contact our support team</4>."
            >
              In 24h your account will be deleted. <br />
              If you need assistance or have any questions, please <br />
              <SupportLink href="mailto:support@hcaptcha.com">
                contact our support team
              </SupportLink>
              .
            </Trans>
          </Message>
          <Message>{t('Thank you')}</Message>
        </InfoContainer>
      </Content>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  background-color: ${Color.grey100};
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 35px;
  left: 35px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const IconWrapper = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background-color: ${Color.yellow100};
  color: ${Color.yellow300};
  padding: 16px;
  box-sizing: border-box;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: ${Color.grey600};
`;

const Message = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${Color.grey500};
  text-align: center;
`;

const SupportLink = styled.a`
  color: ${Color.blue400};
  cursor: pointer;
  text-decoration: none;
`;
