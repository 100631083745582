import { usePostHog } from 'posthog-js/react';

let userInstance: any | null;
let loggerInstance: any | null;
let gclidString: any | null;

declare global {
  interface Window {
    plausible: any;
  }
}

export const setLogger = (logger?: any) => {
  loggerInstance = logger;
};

export const useAnalytics = () => {
  const posthog = usePostHog();
  let timeoutId: number;

  const setUser = (user?: any) => {
    userInstance = user;

    // Set the identifier for user and we will be using ID
    if (userInstance) {
      posthog?.identify(userInstance.getUserId(), {
        plan: userInstance.getPlan() ?? 'Plan not found',
        role_id: userInstance.getRoleId() ?? 'Not found',
        user_id: userInstance.getUserId() ?? 'Not found',
      });
    }
  };

  const setGclid = (gclid?: any) => {
    gclidString = gclid;
  };

  const handleCallbackDelay = (callback?: Function) => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    if (callback) {
      timeoutId = window.setTimeout(() => {
        callback?.();
      }, 500);
    }
  };

  const trackMetric = (name: string = '', props?: any, callback?: Function) => {
    try {
      if (
        userInstance?.isStaff() ||
        userInstance?.isSupport() ||
        userInstance?.isAccessibility()
      ) {
        callback?.();
        return;
      }

      const eventConfig = {
        props: {
          ...props,
          plan: userInstance?.getPlan() ?? 'Plan not found',
          gclid: gclidString ?? 'Not found',
        },
      };

      // Posthog and Plausible metric submit
      posthog?.capture(name, eventConfig);
      window.plausible?.(name, eventConfig);
      handleCallbackDelay(callback);
    } catch (error) {
      loggerInstance?.error(`[Analytics]: Couldn't track the event: ${error}`);
      callback?.();
    }
  };

  return {
    setUser,
    trackMetric,
    setGclid,
  };
};
