import { useTranslation } from 'react-i18next';
import { Color, Icon, Size, PageContent } from '@packages/ui';
import React from 'react';
import styled from 'styled-components';

import Logo from 'shared/components/logo/Logo';

export const ExpiredLink = () => {
  const { t } = useTranslation('portal');

  return (
    <Container>
      <LogoWrapper>
        <Logo width={125} height={32} />
      </LogoWrapper>
      <Content>
        <Icon icon="verificationLinkExpired" iconSize="200" />
        <MainText>{t('Link Expired')}</MainText>
        <SecondaryTexts>
          {t(
            'The link you clicked has either already been used or has expired. Please check your email for a new link, or request a new one by initiating a password reset.',
          )}
        </SecondaryTexts>
      </Content>
    </Container>
  );
};

const Container = styled(PageContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const LogoWrapper = styled.div`
  position: fixed;
  top: 35px;
  left: 35px;
`;

const Content = styled.div`
  width: 280px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${Size.tablet}) {
    width: 560px;
  }
`;

const MainText = styled.p`
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin: 24px 0 16px;

  @media (min-width: ${Size.tablet}) {
    width: 560px;
    font-weight: 700;
    line-height: 44px;
    font-size: 36px;
  }
`;

const SecondaryTexts = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: ${Color.grey600};
  margin: 0;
`;
