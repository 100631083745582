import axios from 'axios';
import * as Sentry from '@sentry/browser';
import pRetry, { Options as RetryOptions } from 'p-retry';

import { APP, USER_LOGGED_OUT } from '../config/Config';
import User from '../models/User';

import rootStore from 'shared/stores/RootStore';

const DEFAULT_RETRY_COUNT = 3;
const DEFAULT_RETRY_DELAY = 2000;
const retryableMethods = ['GET', 'PUT', 'DELETE'];
const nonRetriableStatuses = [401, 403, 404];

/**
 * Checks if the given HTTP status code is retryable.
 *
 * A status code is retryable if it is a client error (4xx) and not one of
 * the statuses excluded from retrying (as these are considered
 * hard errors and should not be retried).
 *
 * @param {number} status - HTTP status code
 * @return {boolean} true if the status code is retryable
 */
const isRetryableStatus = (status: number) => {
  const isClientError = status >= 400;
  const isNonRetryable = nonRetriableStatuses.includes(status);

  return isClientError && !isNonRetryable;
};

/**
 * Creates an instance of the axios client with the given configuration.
 *
 * The created client will have the following features:
 *
 * - The `baseURL` is set to the given `endpoint` or the default `APP.endpoint`.
 * - `withCredentials` is set to `true` to allow cookies to be sent.
 * - The `Content-Type` header is set to `application/json`.
 * - The `X-CSRF-Token` header is set to the given `csrfToken` or the value
 *   returned by `User.getCSRFToken()`.
 * - The client will retry failed requests up to `retryOptions.retries` times
 *   with a minimum delay of `retryOptions.minTimeout` between retries.
 *   The following methods are considered retryable: GET, PUT, DELETE.
 *   The following status codes are not retryable: 401, 403.
 * - If a request returns a 302 or 301 status code, the client will follow the
 *   redirect and update the `window.location.href`.
 * - If a request returns a 401 status code with an error message of
 *   `USER_LOGGED_OUT`, the client will log the user out and reject the promise
 *   with an error containing the same message.
 * - If a request returns an error, the client will capture the error in Sentry
 *   unless the error is a cancel error or the status code is 400 or 429.
 *
 * @param {string | null} csrfToken - The CSRF token to use for requests.
 *   Defaults to `User.getCSRFToken()`.
 * @param {string | null} endpoint - The base URL to use for requests.
 *   Defaults to `APP.endpoint`.
 * @param {RetryOptions} retryOptions - The retry options to use for requests.
 *   Defaults to `{ retries: 3, minTimeout: 2000 }`.
 *
 * @returns {AxiosInstance} The created axios client.
 */
const getClient = (
  csrfToken: string | null = User.getCSRFToken(),
  endpoint: string | null = APP.endpoint,
  retryOptions: RetryOptions = {
    retries: DEFAULT_RETRY_COUNT,
    minTimeout: DEFAULT_RETRY_DELAY,
  },
) => {
  const client = axios.create({
    baseURL: endpoint || APP.endpoint,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
  });

  client.interceptors.response.use(
    response => response,
    async error => {
      const { status, config: initialRequest } = error.response || {};

      if (status === 302 || status === 301) {
        const redirectUrl = error.response.headers.location;
        if (redirectUrl) {
          window.location.href = redirectUrl;
          return Promise.resolve();
        }
      }

      // -> Updates the CSRF token if provided
      if (error.response?.data?.csrf_token) {
        User.setCSRFToken(error.response.data.csrf_token);
      }

      if (status === 401 && error.response.data.error === USER_LOGGED_OUT) {
        rootStore.logout();
        return Promise.reject(new Error(USER_LOGGED_OUT));
      }

      if (
        isRetryableStatus(status) &&
        retryableMethods.includes(initialRequest.method.toUpperCase())
      ) {
        return pRetry(() => client(initialRequest), {
          ...retryOptions,
          onFailedAttempt: retryError => {
            Sentry.captureException(retryError);
          },
        });
      }

      const captureError =
        !axios.isCancel(error) && !(status === 400 || status === 429);

      if (captureError) {
        Sentry.configureScope(scope => {
          scope.setExtra('response', error.response);
        });
        Sentry.captureException(error);
      }

      return Promise.reject(error);
    },
  );

  return client;
};

export default getClient;
