import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Metric, MetricProp, useAnalytics } from '@packages/analytics';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { startAuthentication } from '@simplewebauthn/browser';

import { oAuthOptions } from '../../../data/constants';
import useLoginSubmit from '../../../hooks/handleLogin';
import { LoginFields } from '../Fields';

import { AuthCallbackProps } from 'main/components/auth/OAuthButton/OAuthButton';
import Logger from 'shared/models/Logger';
import ApiClient from 'shared/api/apiClient';
import { IS_LOCAL_ENV, hCaptcha, APP } from 'shared/config/Config';
import { getUrlWithBackurlRedirect, navigateToVerifyLogin } from 'shared/utils';
import { useRootStore } from 'shared/stores';

interface FormDataInterface {
  email: string;
  password: string;
}

const hCaptchaProps = {
  endpoint: hCaptcha.endpoint,
  ...(IS_LOCAL_ENV
    ? {
        host: 'dashboard.local',
      }
    : {}),
};

export interface LoginUserProps {
  setFeedback: (feedback: string) => void;
}

const LoginUser: React.FC<LoginUserProps> = ({ setFeedback }) => {
  const { t } = useTranslation('portal');
  const { trackMetric } = useAnalytics();
  const [formData, setFormData] = useState<FormDataInterface>({
    email: '',
    password: '',
  });
  const captchaRef = useRef<any>(null);
  const [error, setError] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const { handleLogin } = useLoginSubmit(setFeedback, setIsLoggingIn);
  const rootStore = useRootStore();

  useEffect(() => {
    if (error) {
      setFeedback(t('Please contact support'));
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLoginSubmit = (token: string) => {
    trackMetric(Metric.SIGN_IN, {
      client: MetricProp.CLIENT_USER,
      method: MetricProp.METHOD_EMAIL,
    });

    handleLogin({ ...formData, token });
  };

  const handleLoginVerify = async verificationResult => {
    if (!verificationResult) {
      return;
    }

    const { verified: isVerified, is2fa } = verificationResult.data || {};

    if (!isVerified) {
      return;
    }

    if (is2fa) {
      navigateToVerifyLogin();
    } else {
      await rootStore.login();
    }
  };

  const handleConditionalUiPasskeyLogin = async () => {
    let assertionResponse;

    try {
      const { data } = await ApiClient().post(`/passkeys/prepare-login`, {
        token: await getToken(),
      });
      assertionResponse = await startAuthentication(data, true);
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      }

      Logger.error(`Couldn't log in via Conditional UI Passkeys: ${error}`);
    }

    try {
      if (!assertionResponse) {
        return;
      }

      // track if the user actually uses the Conditional UI Passkey Login
      trackMetric(Metric.SIGN_IN, {
        client: MetricProp.CLIENT_USER,
        method: MetricProp.METHOD_CONDITIONAL_UI_PASSKEY,
      });

      const verificationResponse = await ApiClient().post(
        `/passkeys/verify-login-credential`,
        {
          auth_res: JSON.stringify(assertionResponse),
          token: await getToken(),
        },
      );
      const verificationResult = await verificationResponse;

      await handleLoginVerify(verificationResult);
    } catch (error) {
      setFeedback?.(
        t(
          'Passkey failed to validate, please sign in using your password. If the issue persists, please contact support@hcaptcha.com.',
        ),
      );
      Logger.error(`Couldn't log in via Conditional UI Passkeys: ${error}`);
    }
  };

  const handlePasskeyLogin = async () => {
    setIsLoggingIn(true);
    trackMetric(Metric.SIGN_IN, {
      client: MetricProp.CLIENT_USER,
      method: MetricProp.METHOD_REGULAR_PASSKEY,
    });

    try {
      const { data } = await ApiClient().post(`/passkeys/prepare-login`, {
        email: formData.email,
        token: await getToken(),
      });
      const assertionResponse = await startAuthentication(data);

      const verificationResponse = await ApiClient().post(
        `/passkeys/verify-login-credential`,
        {
          auth_res: JSON.stringify(assertionResponse),
          email: formData.email,
          token: await getToken(),
        },
      );
      const verificationResult = await verificationResponse;

      await handleLoginVerify(verificationResult);
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      }

      setFeedback?.(
        t(
          'Passkey failed to validate, please sign in using your password. If the issue persists, please contact support@hcaptcha.com',
        ),
      );
      Logger.error(`Couldn't log in via regular Passkeys: ${error}`);
    }

    setIsLoggingIn(false);
  };

  const getToken = async () => {
    if (IS_LOCAL_ENV) {
      return '';
    }

    if (captchaRef.current?.isReady?.()) {
      const { response: responseToken } = await captchaRef.current?.execute({
        async: true,
      });

      return responseToken;
    }

    await new Promise(resolve => setTimeout(resolve, 500));

    return getToken();
  };

  const handleLoginClick = async () => {
    try {
      const hCaptchaToken = await getToken();

      handleLoginSubmit(hCaptchaToken);
    } catch (error) {
      setFeedback(t('Please contact support'));
    }
  };

  const handleCaptchaError = () => {
    setError(true);
  };

  const handleOAuth = async ({ authType }: AuthCallbackProps) => {
    try {
      if (!captchaRef.current) {
        // Captcha is not ready, have user retry
        setFeedback(t('Please try again.'));
        return;
      }

      setFeedback('');
      const MAX_URL_LENGTH = 4096;
      const { response } = (await captchaRef.current.execute({
        async: true,
      })) as { response: string };

      const option = oAuthOptions.find(
        button => button.name.toLowerCase() === authType,
      );

      let oAuthURL = getUrlWithBackurlRedirect(
        option?.url ??
          `${APP.endpoint}/oauth/${authType}?signup_page=LoginUser&token=${response}`,
      );
      if (oAuthURL.length > MAX_URL_LENGTH) {
        Logger.error(`hCaptcha response token was too long.`);
        // To prevent blocking signing in, we will not send resposne token
        oAuthURL = getUrlWithBackurlRedirect(
          `${APP.endpoint}/oauth/${authType}?signup_page=LoginUser`,
        );
      }

      window.location.href = oAuthURL;
    } catch (e) {
      const reason = t(e?.message ?? '');

      Logger.error(`Couldn't call ${authType} due to ${reason}`);
      setFeedback(t('Please contact support'));
    }
  };

  return (
    <>
      <LoginFields
        buttons={oAuthOptions}
        clientType="user"
        onChange={data => setFormData({ ...formData, ...data })}
        showForm
        isLoggingIn={isLoggingIn}
        onSubmit={handleLoginClick}
        onPasskeyLogin={handlePasskeyLogin}
        onConditionalUiPasskeyLogin={handleConditionalUiPasskeyLogin}
        onOAuth={handleOAuth}
      />
      {!IS_LOCAL_ENV && (
        <HCaptcha
          sitekey={hCaptcha.passiveSitekey}
          onError={handleCaptchaError}
          size="invisible"
          ref={captchaRef}
          // eslint-disable-next-line
          {...hCaptchaProps}
        />
      )}
    </>
  );
};

export default observer(LoginUser);
