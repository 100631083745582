import React, { lazy } from 'react';
import { observer } from 'mobx-react-lite';

const DashboardApp = lazy(() =>
  /* webpackChunkName: "dashboard" */ import('legacy/'),
);

const DashboardRouter = observer(() => {
  return <DashboardApp />;
});

export default DashboardRouter;
