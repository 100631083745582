import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Color,
  BreadCrumbs,
  BreadCrumbType,
  Text,
  Badge,
  BadgeType,
  Icon,
} from '@packages/ui';

import { handleEnterKeyDown } from '../../utils';

import history from 'shared/models/History';

export interface SubHeaderProps {
  title: string;
  description?: string;
  breadCrumbsItems?: BreadCrumbType[];
  actions?: React.ReactNode | React.ReactNode[];
  content?: React.ReactNode;
  isBeta?: boolean;
  className?: string;
  isBackButtonShown?: boolean;
  backUrl?: string;
}

const Areas = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  BREADCRUMBS: 'breadcrumbs',
  ACTIONS: 'actions',
  CONTENT: 'content',
};

export const SubHeader: React.FC<SubHeaderProps> = ({
  title,
  description,
  breadCrumbsItems = [],
  actions,
  content,
  isBeta,
  className,
  isBackButtonShown,
  backUrl = '',
}) => {
  const { t } = useTranslation('generic');

  const handleGoBack = () => {
    if (backUrl) {
      history.push(backUrl);

      return;
    }

    history.goBack();
  };

  return (
    <Container className={className}>
      {isBackButtonShown && (
        <BackPathLink
          tabIndex={0}
          onClick={handleGoBack}
          onKeyPress={handleEnterKeyDown(handleGoBack)}
        >
          <BackUrlIcon icon="chevronRight" /> {t('Back')}
        </BackPathLink>
      )}
      {breadCrumbsItems.length ? (
        <StyledBreadCrumbs
          items={breadCrumbsItems}
          renderLink={({ href, children }) => (
            <PathLink to={href}>{children}</PathLink>
          )}
        />
      ) : null}
      <Title variant="h1">
        <TitleTextWrapper>{title}</TitleTextWrapper>
        {isBeta && <BetaBadge type={BadgeType.BETA} title={t('Beta')} />}
      </Title>
      {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
      {description && (
        <Description variant="default">{description}</Description>
      )}
      {content && <ContentWrapper>{content}</ContentWrapper>}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  margin: 24px 0 8px;
  grid-template-areas:
          "${Areas.BREADCRUMBS} ${Areas.BREADCRUMBS} ${Areas.BREADCRUMBS} ${Areas.ACTIONS}"
          "${Areas.TITLE} ${Areas.TITLE} ${Areas.TITLE} ${Areas.ACTIONS}"
          "${Areas.DESCRIPTION} ${Areas.DESCRIPTION} ${Areas.DESCRIPTION} ${Areas.DESCRIPTION}"
          "${Areas.CONTENT} ${Areas.CONTENT} ${Areas.CONTENT} ${Areas.CONTENT}";

  @media (min-width: 738px) {
    max-width: 100%;
    margin: 48px 0 8px;
  }
`;

const Title = styled(Text)`
  grid-area: ${Areas.TITLE};
  display: flex;
  margin: 0 0 16px 0;
  overflow: hidden;
`;

const TitleTextWrapper = styled.div`
  font-size: 24px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PathLink = styled(Link)`
  &:focus,
  &:hover,
  &:active {
    ${Text} {
      color: ${Color.blue400};
    }
  }
`;

const Description = styled(Text)`
  grid-area: ${Areas.DESCRIPTION};
`;

export const StyledBreadCrumbs = styled(BreadCrumbs)`
  grid-area: ${Areas.BREADCRUMBS};
  margin-bottom: 16px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  grid-area: ${Areas.CONTENT};
`;

const ActionsWrapper = styled.div`
  grid-area: ${Areas.ACTIONS};
  justify-self: end;
`;

const BetaBadge = styled(Badge)`
  margin-left: 8px;
`;

const BackPathLink = styled.div`
  cursor: pointer;
  grid-area: ${Areas.BREADCRUMBS};
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
`;

const BackUrlIcon = styled(Icon)`
  transform: rotate(180deg);
`;
