import React from 'react';
import styled from 'styled-components';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Color, Size } from '@packages/ui';

import { PageFooter } from '../PageFooter';

import {
  IS_FOUNDATION,
  TEXT_BRANDING,
  LINK_BRANDING,
} from 'shared/config/Config';
import Account from 'shared/models/Account';
import Logo from 'shared/components/logo/Logo';

export interface PortalProps {
  routes: any;
  children: any;
}

const Portal: React.FC<PortalProps> = ({ routes, children }) => {
  const isPaymentPage = window.location.pathname.includes('/payment');
  const isSelectAccount = window.location.pathname.includes('/choose_account');
  const isLinkExpiredPage = window.location.pathname.includes(
    '/invalid_confirmation_link',
  );

  if (
    Account.loggedIn &&
    window.location.pathname !== '/password_change' &&
    window.location.pathname !== '/choose_account' &&
    window.location.pathname !== '/email_confirmation' &&
    window.location.pathname !== '/invalid_confirmation_link' &&
    !isPaymentPage
  ) {
    return <Redirect to="/" />;
  }

  const height = IS_FOUNDATION ? 190 : 48;
  const width = IS_FOUNDATION ? 190 : 175;

  const handleLogoClick = () => {
    window.location.href = LINK_BRANDING;
  };

  return (
    <PageWrapper role="main">
      <PortalWrapper>
        <Wrapper>
          {!isPaymentPage && !isSelectAccount && !isLinkExpiredPage && (
            <StyledLogo
              width={width}
              height={height}
              stack={false}
              compact={false}
              title={`${TEXT_BRANDING} logo`}
              onClick={handleLogoClick}
            />
          )}
          <Switch>
            {routes.map(({ path, component }, key) => (
              <Route exact key={key} path={path} component={component} />
            ))}
            <Redirect to="/" />
          </Switch>
        </Wrapper>
      </PortalWrapper>
      <PageFooter />
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  background-color: ${Color.white};
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  overflow-x: hidden;
  @media (max-width: ${Size.mobile}) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
`;

const PortalWrapper = styled.div`
  display: table;
  position: relative;
  width: 100%;
  height: calc(100% - 20px);
`;

const Wrapper = styled.div`
  position: relative;
  display: table-cell;
  vertical-align: middle;
`;

const StyledLogo = styled(Logo)`
  margin: 0 auto;
  z-index: 10;
  padding-bottom: 20px;
`;

export default Portal;
