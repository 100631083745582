import React, { useState, useEffect, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import {
  Panel,
  Text,
  Color,
  Button,
  Icon,
  useToast,
  PageContent,
  Loader,
} from '@packages/ui';
import { Metric, MetricProp, useAnalytics } from '@packages/analytics';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import { UnavailablePasskeysOption } from './components';

import Logger from 'shared/models/Logger';
import Account from 'shared/models/Account';
import { arePasskeysBrowserSupported } from 'shared/utils';
import { IS_LOCAL_ENV, hCaptcha, IS_FOUNDATION } from 'shared/config/Config';
import Logo from 'shared/components/logo/Logo';
import { useRootStore } from 'shared/stores';

interface VerifyLoginProps {
  className?: string;
}

const hCaptchaProps = {
  endpoint: hCaptcha.endpoint,
  ...(IS_LOCAL_ENV
    ? {
        host: 'dashboard.local',
      }
    : {}),
};
const logoHeight = IS_FOUNDATION ? 190 : 48;
const logoWidth = IS_FOUNDATION ? 190 : 175;

const VerifyLogin: React.FC<VerifyLoginProps> = ({ className }) => {
  const { t } = useTranslation('generic');
  const { trackMetric } = useAnalytics();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [arePasskeysUnavailable, setArePasskeysUnavailable] = useState(false);
  const rootStore = useRootStore();
  const toast = useToast();
  const captchaRef = useRef<any>(null);

  useEffect(() => {
    const handlePasskeysAvailability = async () => {
      try {
        const arePasskeysSupported = await arePasskeysBrowserSupported();

        setArePasskeysUnavailable(!arePasskeysSupported);
        setIsInitialLoading(false);
      } catch (error) {
        setIsInitialLoading(false);
      }
    };

    handlePasskeysAvailability();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getToken = async () => {
    if (IS_LOCAL_ENV) {
      return '';
    }

    if (captchaRef.current?.isReady?.()) {
      const { response: responseToken } = await captchaRef.current?.execute({
        async: true,
      });

      return responseToken;
    }

    await new Promise(resolve => setTimeout(resolve, 500));

    return getToken();
  };

  const handlePasskeyLogin = async () => {
    setIsLoading(true);
    trackMetric(Metric.VERIFY_LOGIN, {
      client: MetricProp.CLIENT_USER,
      method: MetricProp.VERIFY_LOGIN_PASSKEY,
    });

    try {
      const verificationResult = await Account.verifyLoginViaPasskey(getToken);

      if (verificationResult?.data?.verified) {
        await rootStore.login();
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      }

      toast.error?.(
        t(
          'Verification failed If the issue persists, please contact support@hcaptcha.com',
        ),
      );
      Logger.error(`Couldn't verify login via passkey: ${error}`);
    }

    setIsLoading(false);
  };

  return (
    <Container className={className}>
      <LogoWrapper>
        <Logo width={logoWidth} height={logoHeight} />
      </LogoWrapper>
      <Content>
        <Card>
          <Title variant="h4">{t('Two-Factor Authentication')}</Title>
          {isInitialLoading ? (
            <Loader />
          ) : (
            <VerificationOption>
              <OptionHeader>
                <PasskeyIconContainer>
                  <Icon icon="passkey" iconSize="20" />
                </PasskeyIconContainer>
                <InfoContainer>
                  <Title variant="h4">{t('Passkey as 2FA')}</Title>
                </InfoContainer>
              </OptionHeader>
              {arePasskeysUnavailable ? (
                <UnavailablePasskeys />
              ) : (
                <OptionContent>
                  <Description>
                    {t(
                      'Please complete authentication via your preferred method.',
                    )}
                  </Description>
                  <VerifyButton
                    isabled={isLoading}
                    onClick={handlePasskeyLogin}
                  >
                    {t('Verify')}
                    {isLoading && <Loader size={16} />}
                  </VerifyButton>
                </OptionContent>
              )}
            </VerificationOption>
          )}
        </Card>
        <SupportDetails>
          <Text>{t('Lost access to your 2FA?')}</Text>
          <Text>
            <Trans
              t={t}
              i18nKey="Contact Support at <1>support@hcaptcha.com</1>"
            >
              Contact Support at
              <Link href="mailto:support@hcaptcha.com">
                support@hcaptcha.com
              </Link>
            </Trans>
          </Text>
        </SupportDetails>
        {!IS_LOCAL_ENV && (
          <HCaptcha
            sitekey={hCaptcha.passiveSitekey}
            size="invisible"
            ref={captchaRef}
            // eslint-disable-next-line
              {...hCaptchaProps}
          />
        )}
      </Content>
    </Container>
  );
};

const Container = styled(PageContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const LogoWrapper = styled.div`
  position: fixed;
  top: 35px;
  left: 35px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const Card = styled(Panel)`
  position: relative;
  padding: 32px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const VerificationOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled(Text)`
  text-align: left;
  font-weight: 600;
  font-size: 18px;
`;

const Description = styled(Text)`
  max-width: calc(100% - 32px);
`;

const OptionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const PasskeyIconContainer = styled.div`
  display: inline-flex;
  align-self: flex-start;
  padding: 8px;
  border-radius: 8px;
  color: ${Color.grey500};
  background-color: ${Color.grey100};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const SupportDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Link = styled.a`
  color: ${Color.blue600};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  text-decoration: none;
  margin-left: 4px;

  &:hover,
  &:active {
    color: ${Color.blue400};
  }
`;

const OptionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const VerifyButton = styled(Button)`
  width: 100%;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

const UnavailablePasskeys = styled(UnavailablePasskeysOption)`
  margin-top: 16px;
`;

export default observer(VerifyLogin);
