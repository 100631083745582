import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Form, FormHeader } from '../../components';

import { LoginOrg } from './Org';
import { LoginUser } from './User';
import { LoginViews } from './Views';

import { TEXT_BRANDING } from 'shared/config/Config';

export interface LoginProps {
  location?: any;
}

const Login: React.FC<LoginProps> = ({ location }) => {
  const [feedback, setFeedback] = useState<any>({
    text: '',
    type: '',
  });

  const { t } = useTranslation('portal');

  const formError = (text: string) => {
    setFeedback({ text, type: 'error' });
  };

  return (
    <Form
      ariaLabel={t('{{brand}} Login', {
        brand: TEXT_BRANDING,
      })}
      width={300}
      padding={0}
      minHeight={472}
    >
      <FormHeader withUnderline={false} role="alert" feedback={feedback}>
        {t('Sign In')}
      </FormHeader>
      <LoginViews
        selected={location.pathname.includes('org') ? 'org' : 'user'}
      />
      <Switch>
        <Route path="/login" exact>
          <LoginUser setFeedback={formError} />
        </Route>
        <Route path={['/login/org', '/org/:organization/login']} exact>
          <LoginOrg setFeedback={formError} />
        </Route>
      </Switch>
    </Form>
  );
};

export default Login;
