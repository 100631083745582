import React, { useState, useCallback, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { startAuthentication } from '@simplewebauthn/browser';
import { Color } from '@packages/ui';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Metric, MetricProp, useAnalytics } from '@packages/analytics';

import { Api } from '../../../data/api';
import { oAuthOptions } from '../../../data/constants';
import useLoginSubmit from '../../../hooks/handleLogin';
import { TextInput } from '../../../components/TextInput';
import { SubmitButton } from '../../../components/SubmitButton';
import { LoginFields } from '../Fields';

import { AuthCallbackProps } from 'main/components/auth/OAuthButton/OAuthButton';
import ApiClient from 'shared/api/apiClient';
import { APP, IS_LOCAL_ENV, hCaptcha } from 'shared/config/Config';
import Logger from 'shared/models/Logger';
import { getUrlWithBackurlRedirect, navigateToVerifyLogin } from 'shared/utils';
import { useRootStore } from 'shared/stores';
import { OAuthButtonType } from 'main/data/types/OAuth';

const hCaptchaProps = {
  endpoint: hCaptcha.endpoint,
  ...(IS_LOCAL_ENV
    ? {
        host: 'dashboard.local',
      }
    : {}),
};

declare global {
  interface Window {
    organization: any;
  }
}

interface FormFieldsInterface {
  pwAccess: boolean;
  authButtons?: OAuthButtonType | Array<OAuthButtonType>;
}

interface FormDataInterface {
  email: string;
  password: string;
  org: string;
}

export interface LoginOrgProps {
  match: any;
  history: any;
  location: any;
  setFeedback: (feedback: string) => void;
}

const LoginOrg: React.FC<LoginOrgProps> = ({
  history,
  match,
  location,
  setFeedback,
}) => {
  const { t } = useTranslation('portal');
  const { trackMetric } = useAnalytics();
  const captchaRef = useRef<any>(null);
  const [error, setError] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<FormFieldsInterface>({
    pwAccess: true,
    authButtons: [],
  });
  const rootStore = useRootStore();

  const [formData, setFormData] = useState<FormDataInterface>({
    email: '',
    password: '',
    org: match.params.organization || '',
  });

  useEffect(() => {
    if (error) {
      setFeedback(t('Please contact support'));
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const getToken = async () => {
    if (IS_LOCAL_ENV) {
      return '';
    }

    if (captchaRef.current?.isReady?.()) {
      const { response: responseToken } = await captchaRef.current?.execute({
        async: true,
      });

      return responseToken;
    }

    await new Promise(resolve => setTimeout(resolve, 500));

    return getToken();
  };

  /**
   * Set Organization configuration data
   * @param  {Object} data - Organization options
   * @param  {String} organization - Organization ID
   */
  const setOrgData = useCallback((data, organization: string) => {
    const { auth_saml_enabled, oauth_enabled, password_access_allowed } = data;
    let buttons: any = [];

    if (auth_saml_enabled) {
      buttons = buttons.concat({
        name: 'SAML',
        url: `${APP.endpoint}/org/${organization}/saml/login`,
      });
    }

    if (oauth_enabled) {
      buttons = buttons.concat(oAuthOptions);
    }

    setFormFields({
      pwAccess: password_access_allowed,
      authButtons: buttons,
    });
  }, []);

  /**
   * Make API call to get Organization Configuration
   * @param  {String} organization
   * @return {Object}
   */
  const getOrgConfig = useCallback(
    async organization => {
      try {
        const { data } = await Api.getLoginOptions(organization);
        const {
          auth_saml_enabled,
          oauth_enabled,
          password_access_allowed,
        } = data;

        if (!auth_saml_enabled && !oauth_enabled && !password_access_allowed) {
          return {
            valid: false,
            data: {},
            reason: t('No sign in options found for your organization.'),
          };
        }

        // we have a valid org
        window.organization = organization;

        return { valid: true, data, reason: '' };
      } catch (e) {
        Logger.error(`couldn't get Login Options in LoginOrg: ${e}`);

        const errorMsg =
          e.response && e.response.data
            ? t(e.response.data.message)
            : t('Cannot contact the server');
        return { valid: false, data: {}, reason: errorMsg };
      }
    },
    [t],
  );

  /*
   * Handle organization config fetching
   */
  const handleGetOrgConfig = async () => {
    const { org } = formData;

    if (org === '') {
      setFeedback(t('Please complete the form.'));
      return;
    }

    const { valid, data, reason } = await getOrgConfig(org);

    if (valid) {
      // If Organization found, set as user and config user inputs per Organization settings
      setOrgData(data, org);
      history.push(`/org/${org}/login${window.location.search}`);
    } else {
      setFeedback(reason);
    }
  };

  const handleKeyDown = (evt: KeyboardEvent) => {
    const { keyCode } = evt;
    if (keyCode === 13) {
      // If enter press
      onSubmit();
    }
  };

  useEffect(() => {
    const { path, params } = match;
    const { organization } = params;

    if (path === '/org/:organization/login' && !!organization) {
      const fetchOrgData = async () => {
        const { valid, data } = await getOrgConfig(organization);

        if (!valid) {
          // Comment out below line until we upgrade react router
          // Going directly to unknown org id and setting feedback causes error
          // setFeedback({ text: reason, type: 'error' });

          history.push('/login/org');
        } else {
          if (data?.password_access_allowed) {
            handleConditionalUiPasskeyLogin();
          }

          setOrgData(data, organization);
        }
      };

      fetchOrgData();
    }
  }, [match?.path, match?.params?.organization]); // eslint-disable-line react-hooks/exhaustive-deps

  // Custom hook to handle user login logic. Used in Login.jsx and LoginOrg.jsx
  const { handleLogin } = useLoginSubmit(setFeedback, setIsLoggingIn);

  const isOrgFormShown = () => {
    return location.pathname === '/login/org';
  };

  /**
   * Handle form submit request
   */
  const onSubmit = () => {
    if (isOrgFormShown()) {
      trackMetric(Metric.SIGN_IN_ORG_ID_SUBMIT);
      handleGetOrgConfig();
    } else {
      trackMetric(Metric.SIGN_IN, {
        client: MetricProp.CLIENT_ORGANIZATION,
        method: MetricProp.METHOD_EMAIL,
      });
      handleLogin(formData);
    }
  };

  const handleLoginVerify = async verificationResult => {
    if (!verificationResult) {
      return;
    }

    const { verified: isVerified, is2fa } = verificationResult.data || {};

    if (!isVerified) {
      return;
    }

    if (is2fa) {
      navigateToVerifyLogin();
    } else {
      await rootStore.login();
    }
  };

  const handleConditionalUiPasskeyLogin = async () => {
    let assertionResponse;

    try {
      const { data } = await ApiClient().post(`/passkeys/prepare-login`, {
        token: await getToken(),
      });
      assertionResponse = await startAuthentication(data, true);
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      }

      Logger.error(`Couldn't log in via Conditional UI Passkeys: ${error}`);
    }

    try {
      if (!assertionResponse) {
        return;
      }

      // track if the user actually uses the Conditional UI Passkey Login
      trackMetric(Metric.SIGN_IN, {
        client: MetricProp.CLIENT_USER,
        method: MetricProp.METHOD_CONDITIONAL_UI_PASSKEY,
      });

      const verificationResponse = await ApiClient().post(
        `/org/${formData.org}/passkeys/verify-login-credential`,
        {
          auth_res: JSON.stringify(assertionResponse),
          token: await getToken(),
        },
      );
      const verificationResult = await verificationResponse;

      await handleLoginVerify(verificationResult);
    } catch (error) {
      setFeedback?.(
        t(
          'Passkey failed to validate, please sign in using your password. If the issue persists, please contact support@hcaptcha.com.',
        ),
      );
      Logger.error(`Couldn't log in via Conditional UI Passkeys: ${error}`);
    }
  };

  const handlePasskeyLogin = async () => {
    setIsLoggingIn(true);
    trackMetric(Metric.SIGN_IN, {
      client: MetricProp.CLIENT_USER,
      method: MetricProp.METHOD_REGULAR_PASSKEY,
    });

    try {
      const { data } = await ApiClient().post(`/passkeys/prepare-login`, {
        email: formData.email,
        token: await getToken(),
      });
      const assertionResponse = await startAuthentication(data);
      const verificationResponse = await ApiClient().post(
        `/org/${formData.org}/passkeys/verify-login-credential`,
        {
          auth_res: JSON.stringify(assertionResponse),
          email: formData.email,
          token: await getToken(),
        },
      );
      const verificationResult = await verificationResponse;

      await handleLoginVerify(verificationResult);
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      }

      setFeedback?.(
        t(
          'Passkey failed to validate, please sign in using your password. If the issue persists, please contact support@hcaptcha.com',
        ),
      );
      Logger.error(`Couldn't log in via regular Passkeys: ${error}`);
    }

    setIsLoggingIn(false);
  };

  const handleCaptchaError = () => {
    setError(true);
  };

  const handleOAuth = async ({ authType }: AuthCallbackProps) => {
    try {
      if (!captchaRef.current) {
        // Captcha is not ready, have user retry
        setFeedback(t('Please try again.'));
        return;
      }

      setFeedback('');
      const MAX_URL_LENGTH = 4096;
      const { response } = (await captchaRef.current.execute({
        async: true,
      })) as { response: string };

      const option = (formFields.authButtons as OAuthButtonType[]).find(
        button => button.name.toLowerCase() === authType,
      );

      let oAuthURL = getUrlWithBackurlRedirect(
        option?.url ??
          `${APP.endpoint}/oauth/${authType}?signup_page=LoginOrg&token=${response}`,
      );
      if (oAuthURL.length > MAX_URL_LENGTH) {
        Logger.error(`hCaptcha response token was too long.`);
        // To prevent blocking signing in, we will not send resposne token
        oAuthURL = getUrlWithBackurlRedirect(
          `${APP.endpoint}/oauth/${authType}?signup_page=LoginOrg`,
        );
      }

      window.location.href = oAuthURL;
    } catch (e) {
      const reason = t(e?.message ?? '');

      Logger.error(`Couldn't call ${authType} due to ${reason}`);
      setFeedback(t('Please contact support'));
    }
  };

  return (
    <>
      {isOrgFormShown() ? (
        <InputWrapper>
          <StyledLabel htmlFor="organization">
            {t('Organization ID')}
          </StyledLabel>
          <InputOrganization
            placeholder={t('Enter your organization ID here')}
            value={formData.org}
            onKeyDown={handleKeyDown}
            onChange={val => setFormData({ ...formData, org: val })}
            label="organization"
            ariaRequired
          />
        </InputWrapper>
      ) : (
        <LoginFields
          buttons={formFields.authButtons}
          clientType="organization"
          onChange={data => setFormData({ ...formData, ...data })}
          showForm={formFields.pwAccess}
          isLoggingIn={isLoggingIn}
          onSubmit={onSubmit}
          onPasskeyLogin={handlePasskeyLogin}
          onOAuth={handleOAuth}
        />
      )}
      {isOrgFormShown() && formFields.pwAccess && (
        <ButtonWrapper>
          <SubmitButton
            onClick={onSubmit}
            aria-live="polite"
            aria-relevant="text"
            aria-label={isLoggingIn ? t('Logging in...') : t('Submit')}
            disabled={isLoggingIn}
          >
            {isLoggingIn ? t('Logging in...') : t('Submit')}
          </SubmitButton>
        </ButtonWrapper>
      )}
      {!IS_LOCAL_ENV && (
        <HCaptcha
          sitekey={hCaptcha.passiveSitekey}
          onError={handleCaptchaError}
          size="invisible"
          ref={captchaRef}
          // eslint-disable-next-line
          {...hCaptchaProps}
        />
      )}
    </>
  );
};

const StyledLabel = styled.label`
  color: ${Color.grey600};
  font-weight: 500;
`;

const InputWrapper = styled.div`
  margin-top: 15px;
`;

const InputOrganization = styled(TextInput)`
  margin-bottom: 15px;
  position: relative;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default withRouter(observer(LoginOrg));
