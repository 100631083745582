import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Color, Size, useToast } from '@packages/ui';

import Account from 'shared/models/Account';

const EmailConfirmation = () => {
  const { t } = useTranslation('portal');
  const toast = useToast();

  const handleResendConfirmationEmail = async () => {
    const { error, res } = await Account.resendConfirmationEmail();

    const defaultErrorMessage =
      'Failed to send confirmation email. Please try again or contact support@hcaptcha.com';

    if (error) {
      toast.error(error.message || t(defaultErrorMessage));

      return;
    }

    if (!res?.ok) {
      toast.error(res?.reason || t(defaultErrorMessage));

      return;
    }

    toast.success(t('Success! Your email has been re-sent.'));
  };

  return (
    <FormWrapper>
      <LetterIcon icon="confirmationLetter" iconSize={230} />
      <MainText>{t('Congratulations!')}</MainText>
      <SecondaryTexts>
        {t(
          'Thank you for signing up with hCaptcha! Please check your inbox for a verification email and instructions to help you get started.',
        )}
      </SecondaryTexts>
      <ActionContainer>
        <SecondaryTexts>
          {t('To re-send the verification email, click the button below.')}
        </SecondaryTexts>
        <StyledButton
          dataCy="createOrg"
          onClick={handleResendConfirmationEmail}
        >
          {t('Re-send Verification')}
        </StyledButton>
      </ActionContainer>
    </FormWrapper>
  );
};

// HTML Elements
const LetterIcon = styled(Icon)`
  margin-bottom: -40px;
`;

const FormWrapper = styled.div`
  width: 280px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${Size.tablet}) {
    width: 560px;
  }
`;

const MainText = styled.p`
  font-weight: 600;
  font-size: 36px;
  line-height: 5px;
  text-align: center;
`;

const SecondaryTexts = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${Color.grey600};
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  border-top: 1px dashed ${Color.grey300};
  padding-top: 10px;
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  box-sizing: border-box;
  padding: 8px 16px;
  gap: 8px;
  height: 40px;
  border-radius: 4px;
  color: ${Color.blue400};
  border: 1px solid ${Color.blue400};
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  background-color: transparent;
  &:hover {
    color: ${Color.white};
  }
`;

export default EmailConfirmation;
