// NPM
import { useTranslation } from 'react-i18next';

// Models
import Account from 'shared/models/Account';
import Logger from 'shared/models/Logger';
// Store
import { useRootStore } from 'shared/stores';

export default function useLoginSubmit(
  onError: any,
  setIsLoggingIn: (val: boolean) => void,
) {
  const store = useRootStore();
  // get translated string
  const { t } = useTranslation('portal');

  const handleLogin = async (formData: any) => {
    const { email, password, token } = formData;

    if (email === '' || password === '') {
      onError(t('Please complete the form.'));
      return;
    }

    try {
      setIsLoggingIn(true);
      await Account.login(email, password, token);
      setIsLoggingIn(false);

      await store.login();
    } catch (e) {
      Logger.error(`couldn't login in handleLogin: ${e}`);
      const errorMsg =
        e.message === 'Failed to fetch'
          ? t('Cannot contact the server')
          : t(e.message);
      onError(errorMsg);
      setIsLoggingIn(false);
    }
  };

  return {
    handleLogin,
  };
}
