import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Panel, Icon, Text, Color } from '@packages/ui';
import { Metric, useAnalytics } from '@packages/analytics';

import { PageWrapper, Wrapper, PageLogo } from './components';

const SuccessPayment = () => {
  const { t } = useTranslation('portal');
  const { trackMetric } = useAnalytics();

  const proceedToNextStep = () => {
    window.location.href = `${window.location.origin}/welcome`;
  };

  const handleClick = () => {
    trackMetric(Metric.PRO_PLAN_GO_TO_DASHBOARD, {}, proceedToNextStep);
  };

  return (
    <PageWrapper>
      <PageLogo />
      <Wrapper>
        <StyledPanel>
          <Icon icon="successCheckmark" iconSize="60" fill={Color.blue300} />
          <Text variant="h1">{t('Thank You!')}</Text>
          <Button variant="default" onClick={handleClick}>
            {t('Go to dashboard')}
          </Button>
        </StyledPanel>
      </Wrapper>
    </PageWrapper>
  );
};

const StyledPanel = styled(Panel)`
  width: 330px;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  transform: translateY(-48px);
`;

export default SuccessPayment;
