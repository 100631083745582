export const convertHexToRgba = (hex: string, opacity: number = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const generateColor = (index?: number) => {
  const COLORS = [
    '#0bb4ff',
    '#e60049',
    '#50e991',
    '#e6d800',
    '#9b19f5',
    '#ffa300',
    '#dc0ab4',
    '#b3d4ff',
    '#00bfa0',
  ];

  if (typeof index === 'number' && index <= COLORS.length) return COLORS[index];

  let color = '#';
  for (let i = 0; i < 3; i += 1)
    color += `0${Math.floor(Math.random() * 200).toString(16)}`.slice(-2);

  return color;
};
