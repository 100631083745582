import { makeAutoObservable, runInAction } from 'mobx';

import History, { getQuery } from '../models/History';
import Account, { User } from '../models/Account';
import Logger from '../models/Logger';
import SubscriptionApi from '../api/subscriptionApi';

import UserStore from './UserStore';
import SubscriptionStore from './SubscriptionStore';

import { constructSafeUrl, isRedirectUrlAllowed } from 'shared/utils';

const BACK_URL_PARAM = 'backurl';

export class RootStore {
  isLoggedIn = false;

  isLoading = false;

  isReady = false;

  loadingMessage = '';

  userStore = new UserStore();

  subscriptionStore = new SubscriptionStore(SubscriptionApi);

  width = document.body ? document.body.clientWidth : 0;

  error = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  logout = async () => {
    this.setIsLoading(true);

    await Account.logout();

    runInAction(() => {
      this.setIsLoading(false);
      this.setIsLoggedIn(false);
      this.userStore = new UserStore();
      this.subscriptionStore = new SubscriptionStore(SubscriptionApi);
    });

    History.push('/login');
  };

  login = async () => {
    if (Account.loaded || this.isLoading) return;

    this.setIsLoading(true);

    const backUrl = getQuery(BACK_URL_PARAM)?.[BACK_URL_PARAM];
    // Some backUrl might have a hash (e.g anchors to documentation sections), so we need to keep it
    const backUrlHash = window.location.hash;

    const shouldRedirectToBackUrl = backUrl && isRedirectUrlAllowed(backUrl);

    // if initialization takes more than 3s, show a message below the loading animation
    const intervalID = setInterval(() => {
      this.setLoadingMessage('Still Loading...');
    }, 3000);

    try {
      await Account.retrieve();

      if (shouldRedirectToBackUrl) {
        window.location.href = constructSafeUrl(backUrl, backUrlHash);
        return;
      }

      const userData = await User.getData();

      runInAction(() => {
        this.userStore.setData(userData);
      });

      if (History.location?.state?.from) {
        // If user tries to access another route than the default '/' path,
        // redirect user to that route after login.
        History.push(History.location.state.from);
      }
    } catch (e) {
      runInAction(() => {
        this.setIsLoading(false);
        this.setLoadingMessage('');
      });
      Logger.error(
        `couldn't getData from User or fetch RequesterData in appStore: ${e}`,
      );
    } finally {
      // set loading msg back to empty, so in case user logs out and logs back in
      // they wont see the "Still loading" message straight away
      runInAction(() => {
        if (!shouldRedirectToBackUrl) {
          this.setIsLoading(false);
        }
        this.setIsLoggedIn(true);
        this.setLoadingMessage('');
      });
      clearInterval(intervalID);
    }
  };

  setIsLoggedIn = (value: boolean) => {
    this.isLoggedIn = value;
  };

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
    this.isReady = !value;
  };

  setLoadingMessage = (msg: string) => {
    this.loadingMessage = msg;
  };

  setWidth = (width: number) => {
    this.width = width;
  };
}

const rootStoreInstance = new RootStore();

export default rootStoreInstance;
